import React from "react"
import FotoCafae from "@components/pageCasosdeExito/image/imgPhotoEmpresa/clinicaAviva/FotoClinicaAviva.png"
import FotoCafae2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/clinicaAviva/FotoClinicaAviva2.png"

//logo de empresas clientes
import ClinicaAviva from "@components/pageCasosdeExito/image/logoscustomer/ClinicaAviva.svg"

//iconos de modulos
import whatsapp from "@components/pageCasosdeExito/image/iconcards/whatsapp.svg"
import chatbots from "@components/pageCasosdeExito/image/iconcards/chatbot.svg"
import gestion from "@components/pageCasosdeExito/image/iconcards/gestion.svg"

//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/clinicaAviva/img1.svg"
import metrica2 from "@components/pageCasosdeExito/image/imgMetric/clinicaAviva/img2.svg"

const text1 = (
  <span className="">
    Clínica Aviva, parte del grupo Intercorp, es una clínica multiespecialidad
    con diferentes servicios de salud, como emergencia, hospitalización,
    consultas, procedimientos quirúrgicos y ambulatorios. Con un staff de más de
    200 colaboradores, su consigna es brindar un servicio de calidad al alcance
    de todos.
    <br /> <br />
    Con la implementación de Beex, la organización ha logrado automatizar su
    proceso de citas médicas por WhatsApp con chatbots que las agendan,
    reagendan y cancelan. Con esto, sus usuarios con capaces de agendar citas
    hasta 30% más rápido.
  </span>
)

const text2 = (
  <span>
    Ubicada en Lima Norte, Clínica Aviva está orientada a mejorar la experiencia
    de los pacientes en general, a través de un servicio oportuno y de calidad
    en las más de 20 especialidades con las que cuenta. Como, pediatría,
    ginecología, medicina interna, medicina general, obstetricia, cardiología,
    neonatología, radiología, cirugía y muchas más.
    <br /> <br />
    Con moderna infraestructura, equipamiento de primera y personal con trato
    amable, la marca perteneciente al grupo Intercorp, el más grande
    conglomerado empresarial peruano, apuesta por la prevención, el cuidado y
    salud de más ciudadanos, con sus 2 programas principales: Avivacura,
    servicios para recuperar tu salud y tratar tus urgencias médicas, y con
    Avivacuida, que son servicios dirigidos a la prevención de enfermedades.
    <br /> <br />
    Luis Ordinola, Coordinador de Mejora de Procesos de Clínica Aviva, ha sido
    el contacto principal entre la marca y Beex para la implementación del
    proceso de automatización de citas por WhatsApp.
    <br /> <br />
    Como mencionamos, Clínica Aviva tiene como una de sus principales misiones,
    brindar un servicio A1 a sus usuarios, siempre acompañados de tecnología.
    Por ello, vieron la necesidad de estar en el canal de más uso de sus
    pacientes, el cual, sin duda es WhatsApp. La app, que es la plataforma más
    usada a nivel mundial, fue elegida como canal para implementar chatbots que
    automaticen su gestión de citas.
  </span>
)

const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
    “Con Beex hemos automatizado toda la atención a nuestros pacientes para
    reservar una cita médica. Además, es una herramienta bastante completa con
    funcionalidades que nos permiten tener a modo global un resumen de la
    situación del canal en el momento que lo necesitemos.”
  </p>
)

const text5 = (
  <span>
    Por ello, gracias a la coordinación de los equipos, el proceso de
    implementación fue un éxito. Primero, coordinando todas las integraciones
    necesarias entre el ERP interno de Clínica Aviva y Beex.
    <br /> <br />
    A través de APIs, la integración se hizo de manera eficaz en el tiempo
    estimado desde el principio. Sin duda, gracias al compromiso del equipo de
    TI y operaciones de Clínica Aviva, junto con nuestro equipo de operaciones, se logró el objetivo.
    <br /> <br />
    Con conectores en tiempo real, Beex es capaz de recibir y enviar
    información del ERP de Clínica Aviva, por lo que cada que hay una agenda,
    reagenda o cancelación de una cita, se ve reflejado en tiempo real en sus
    sistemas.
    <br /> <br />
    Como menciona Luis, este punto de partida era ideal tenerlo trabajado de
    forma eficiente, ya que era la base de todo el proyecto. Sin duda, la
    estructura de APIs de nuestra plataforma ayudó para este fin porque Aviva puede
    consumirlo cada vez que lo necesite y así tener comunicación bidireccional
    entre ambas plataformas.
  </span>
)
const text6 = (
  <span>
    “La implementación se dio de acuerdo con lo planificado y siempre se tuvo
    soporte constante. Ahora, toda cita agendada se ve en nuestros sistemas, lo
    cual hace que no sea necesaria la intervención de una persona durante el
    proceso o para concluirlo.”
  </span>
)

const text7 = (
  <span>
    Como lo mencionamos, el canal ideal para automatizar el proceso de citas de
    Clínica Aviva fue WhatsApp. Todos sus usuarios se sienten cómodos y usan la
    app, así que la decisión no tomó mucho tiempo de evaluación.
    <br /> Con esto en mente, Clínica Aviva encontró en Beex un socio ideal
    que vaya con la metodología de trabajo ágil que tienen para implementar
    chatbots en WhatsApp con integraciones incluidas, de forma eficaz.
    <br /> Actualmente, cualquier usuario puede chatear al WhatsApp oficial de
    Clínica Aviva y realizar un proceso de agenda de citas que contemplan:
    <br />
    - Especialidades.
    <br />
    - Doctores. <br />
    - Horarios.
    <br />
    - Si es para paciente existente o familiar.
    <br /> <br />
    Considerando la importancia del canal y del proceso a implementar, el cual
    es uno core del negocio, la cantidad de citas agendadas aumentó
    considerablemente en el tiempo post-implementación, llegando a ocupar el 20%
    en comparación del resto de canales digitales.
  </span>
)
const text8 = (
  <span>
    “Con los chatbots por WhatsApp hemos controlado el incremento de demanda en
    las reservas de citas y optimizado tiempos, reduciéndolos sustancialmente.
    Además, la cantidad de casos que llegan a nuestros agentes son mínimos y el
    nivel de conversión para estos aumentó drásticamente.”
  </span>
)

const text9 = (
  <p>
    Como siempre, un punto resaltante de la experiencia que brinda Beex a
    sus clientes, es su servicio de soporte. Con Clínica Aviva, al ser una
    implementación que involucraba un canal principal para uno de los procesos
    más importantes, se brindó un acompañamiento A1.
    <br /> <br />
    Sin duda, algo que fue vital para que la implementación y post-producción
    fue el entendimiento que se dio entre equipos. Los equipos de Clínica Aviva
    y Beex se entendieron desde el primer momento y fue ideal para el
    ambicioso proyecto.
    <br /> <br />
    Como menciona Luis, se cumplió con lo que se había prometido durante el
    tiempo de negociación y que consideraron entre todas las propuestas, con un
    tiempo de respuesta adecuado conforme a la necesidad comunicada.
  </p>
)

const text10 = (
  <span>
    “Sin duda, recomendaría los servicios de Beex porque considero que la
    implementación y el soporte son bastante personalizados y, además,
    consideran y toman los requerimientos con alta prioridad, lo cual facilita
    el proceso de adaptación y de mejora continua.”
  </span>
)
const text11 = (
  <p>
    Estos son algunos procesos con los que Beex ayuda
    a Clínica Aviva en su operación de atención al cliente:
  </p>
)
const text12 = (
<p>
Sin duda, ha sido un gusto para nosotros ayudar a Clínica Aviva en la implementación de un proyecto tan ambicioso por WhatsApp. La sinergia creada entre equipos ha sido fundamental no solo para una exitosa salida a producción, sino para una gran relación comercial que se sigue fortaleciendo en el tiempo.

</p>
)


const data = {
  intro: {
    image: ClinicaAviva,
    link: "https://youtu.be/r4Jy_SnDCMc",
    classlogo: "logo__clientes--casa",
    text1: text1,
    text2: text2,
    text3: text3,
    fotoone: FotoCafae,
    text4: "",
    title5: "Integración total",
    text5: text5,
    text6: text6,
    title7: "Chatbots en WhatsApp",
    text7: text7,
    text8: text8,

    fototwo: FotoCafae2,
    title9: "Soporte total",
    text9: text9,
    text10: text10,
    text11: text11,
    text12: text12,
    metric1: metrica1,
    metric2: metrica2,
  },

  cards: [
    {
      title: "WhatsApp",
      img: whatsapp,
      info:
        "Canal oficial de WhatsApp Business API para su operación de atención al cliente. Beex logra que sus clientes conversen con Clínica Aviva mediante la app más usada a nivel mundial. Contando con enrutamiento inteligente, asignación de casos y más.",
    },
    {
      title: "Chatbots",
      img: chatbots,
      info:
        "Implementación de chatbots que agendan, reagendan y cancelan citas de forma inteligente por WhatsApp. Diseño y creación por rangos horarios para compartir respuestas eficaces y rápidas, con disponibilidad 24x7, los 365 días del año.",
    },
    {
      title: "Gestión multiagente",
      img: gestion,
      info:
        "Creación y administración de múltiples usuarios para gestionar bajo una sola cuenta de WhatsApp. Gestión de colas de atención asignadas a chatbots con enrutamiento inteligente de casos.",
    },
  ],
}

export default data
